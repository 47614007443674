(function($) {
  'use strict';

  function news() {
    var SHOW_ITEM = 1;

    new Vue({
      el: '#News',
      data: {
        itemCounter: 0,
        showCounter: 0,
        isShowMoreButton: false,
        news: []
      },
      created: function() {
        var _this = this;

        $.ajax({
          url: '//www.sonymusic.co.jp/json/v2/PR/kyoechanPR/information/list/start/0/count/10',
          type: 'GET',
          dataType: 'jsonp',
          crossDomain: true,
          charset: 'UTF-8',
          jsonpCallback: 'callback'
        }).done(function(data) {
          for (var i = 0; i < data.items.articles.length; i++) {
            _this.news.push({
              isActive: false,
              isOpen: false,
              id: _this.itemCounter++, // data.items.articles[i].id,
              date: data.items.articles[i].date,
              title: data.items.articles[i].title,
              content: data.items.articles[i].article
            });
          }
          _this.showNextItem();
        });
      },
      methods: {
        showNextItem: function() {
          for (var i = 0; i < SHOW_ITEM; i++) {
            this.news[this.showCounter].isActive = true;
            this.showCounter += 1;
            if (this.news.length <= this.showCounter) {
              break;
            }
          }
          this.isShowMoreButton = this.news.length > SHOW_ITEM && this.news.length > this.showCounter;
        },
        toggleAccordion: function(id) {
          this.news[id].isOpen = !this.news[id].isOpen;
        },
        enter: function(element) {
          var elementHeight = element.getBoundingClientRect().height;
          element.style.height = 0;
          element.offsetHeight; // reflow
          element.style.height = elementHeight + 'px';
        },
        afterEnter: function(element) {
          element.style.height = null;
        },
        leave: function(element) {
          var elementHeight = element.getBoundingClientRect().height;
          element.style.height = elementHeight + 'px';
          element.offsetHeight; // reflow
          element.style.height = 0;
        },
        afterLeave: function(element) {
          element.style.height = null;
        }
      }
    });
  }
  
  function navigation() {
    var idRegexp = new RegExp(/^[a-zA-Z]+[\w|\-|:|.]+$/),
        currentAnchor = location.href.split('#'),
        toggle;

    new Vue({
      el: '.l-header',
      data: {
        isEnable: true,
        isFixed: false,
        isActive: false,
        enableTransition: false
      },
      mounted: function() {
        var _this = this,
            isChangeFixed = false,
            scrollY = 0,
            prevScrollY = 0,
            scrollDirection = 0;

        $(this.$el).data('vm-header', this);
        $(window).on('scroll', function() {
          prevScrollY = scrollY;
          scrollY = window.pageYOffset;
          scrollDirection = scrollY - prevScrollY;

          if (isChangeFixed) {
            isChangeFixed = false;
            _this.enableTransition = true;
          }
          if (scrollY <= 0) {
            _this.isFixed = false;
            _this.isActive = false;
            _this.enableTransition= false;
          } else {
            if (scrollY > 130) {
              if (!_this.isFixed) {
                _this.isFixed = true;
                isChangeFixed = true;
              }
            } else {
              if (_this.isFixed) {
                _this.isFixed = false;
              }
            }
          }
          if (_this.isEnable && _this.isFixed) {
            _this.isActive = scrollY <= 0 || scrollDirection <= 0;
          }
        });
      },
      methods: {
        openMenu: function() {
          vueHub.$emit('openMenu');
        }
      }
    });
    toggle = new Vue({
      el: '.l-toggle',
      data: {
        isShow: false
      },
      created: function() {
        var _this = this;
        vueHub.$on('openMenu', function() {
          _this.isShow = true;
        });
        vueHub.$on('closeMenu', function() {
          _this.isShow = false;
        });
      },
      methods: {
        open: function() {
          vueHub.$emit('openMenu');
        },
        close: function() {
          vueHub.$emit('closeMenu');
        }
      }
    });
    new Vue({
      el: '.l-toggleWrap',
      data: {
        isShow: false
      },
      created: function() {
        var _this = this;
        vueHub.$on('openMenu', function() {
          _this.isShow = true;
        });
        vueHub.$on('closeMenu', function() {
          _this.isShow = false;
        });
      },
      methods: {
        open: function() {
          vueHub.$emit('openMenu');
        },
        close: function() {
          vueHub.$emit('closeMenu');
        }
      }
    });

    $(window).on('unload', function() {
      if (toggle.isShow) {
        vueHub.$emit('closeMenu');
      }
    });
    $('.l-container').on('click', 'a', function(event) {
      var $target = $(event.target),
          thisAnchor = this.href.split('#');

      if (toggle.isShow) {
        if ($target.closest('.l-toggle').length !== 0) {
          if (currentAnchor[0] === thisAnchor[0] && thisAnchor.length > 1 && idRegexp.test(thisAnchor[1])) {
            vueHub.$emit('closeMenu');
          }
        }
      }
    });
  }
  
  var scrollTarget = function($target, duration) {
    var $header = $('.l-header'),
        targetY;

    if ($target.length > 0) {
      targetY = Math.ceil($target.offset().top);
      targetY -= $header.height();
      if (targetY < 0) {
        targetY = 0;
      }
      if (duration === 0) {
        $(window).scrollTop(targetY);
      } else {
        $('html, body').animate({scrollTop: targetY}, duration, 'swing');
      }
    }
  };

  function hashScroll() {
    var $container = $('.l-container'),
        idRegexp = new RegExp(/^[a-zA-Z]+[\w|\-|:|.]+$/),
        currentAnchor = location.href.split('#');

    $container.on('click', 'a', function() {
      var thisAnchor = this.href.split('#');

      if (currentAnchor[0] === thisAnchor[0] && thisAnchor.length > 1 && idRegexp.test(thisAnchor[1])) {
        scrollTarget($('#' + thisAnchor[1]), 500);
        return false;
      }
    });
  }

  function kyoeVisibility() {
    var $window = $(window),
        $pagetop = $('.c-img__kyoeSP'),
        isHidden = false;
    var scrollHandler = function() {
      if ($window.scrollTop() > 50) {
        if (!isHidden) {
          $pagetop.addClass('-hidden');
          isHidden = true;
        }
      } else {
        if (isHidden) {
          $pagetop.removeClass('-hidden');
          isHidden = false;
        }
      }
    };

    $window.on('scroll', scrollHandler);
    scrollHandler();
  }

  const kyoe = document.getElementById('kyoe');
	const wing = document.querySelector('.r-wing');
	
	kyoe.addEventListener('click', fliping);
	wing.addEventListener('animationend', flipend);
	
	function fliping(e) {
		kyoe.classList.add('flip');
	}
	function flipend(e) {
		kyoe.classList.remove('flip');
	}
	function blink(a, b, fn, time) {
		kyoe.classList.remove('blink' + a);
		kyoe.classList.add('blink' + b);
		setTimeout(fn, time);
	}
	setTimeout(blinking, 1000);
	function blinking() { blink(2, 1, blinking2, 3200); }
	function blinking2() { blink(1, 2, blinking3, 280); }
	function blinking3() { blink(2, 1, blinking4, 2400); }
	function blinking4() { blink(1, 2, blinking, 8000); }

  function instagramFeed() {
		var feed = new Instafeed({
			limit: 1,
			get: 'user',
			userId: '12306772294',
			clientId: '297f8f5b075d4a289ff8b188c763bdfb',
			accessToken: '12306772294.297f8f5.77fe7bc2702e451ead25f032aad8911b',
			//links: true,
			resolution: 'low_resolution', // thumbnail (default) - 150x150 | low_resolution - 306x306 | standard_resolution - 612x612
			filter: function(data) {
				//console.log(data);
				if (data.caption && data.caption.text) {
					data.caption.text = textEscape(data.caption.text);
				}
				return true;
			},
			template: '<li class="item"><a href="{{link}}" target="_blank" style="background-image:url({{image}})"><img src="./img/common/blank.gif" alt="{{caption}}"></a></li>'
		});
		feed.run();
	}
	function textEscape(cap) {
		var caption = cap;
		caption = caption.replace(/&/g, '&amp;');
		caption = caption.replace(/</g, '&lt;');
		caption = caption.replace(/>/g, '&gt;');
		caption = caption.replace(/"/g, '&quot;');
		caption = caption.replace(/'/g, '&#039;');
		caption = caption.replace(/\r?\n/g, ' ');
		//console.log(caption);
		return caption;
	}
  
  var vueHub = new Vue();
  
  news();
  navigation();
  hashScroll();
  kyoeVisibility();
  instagramFeed();
})(jQuery);
